export const fr = {
  homeTitle: "À vélo ! 🚴‍♂️",
  homeSubtitle: "10 jours pour relier <b>Paris</b> à <b>Marseille</b>",
  followMe: "Suivez l'aventure en direct sur cette page !",
  dateAnnouncement: "Départ le <b>28 avril 2024</b> à <b>10h</b>",
  countdownTitle: "Départ dans",
  stepTitle: "Étape <step>{currentStep}</step> sur {totalSteps}",
  days: "{count}j",
  hours: "{count}h",
  minutes: "{count}m",
  seconds: "{count}s",
  goToDetails: "Découvrir le projet",
  goToSteps: "Découvrir les étapes",
  waiting: "En attendant ⬇️",
  distance: "<b>{distance}</b> km",
  duration: "<b>{duration}</b>",
  elevation: "D+ <b>{positive}</b> m, D- <b>{negative}</b> m",
  APIError: "Erreur lors de la récupération des données",
  APIErrorStep: "Erreur lors de la récupération des données de l'étape",
  APIErrorTrack: "Erreur lors de la récupération des données de parcours",
  APIErrorElevationProfile:
    "Erreur lors de la récupération du profil de dénivelé",
  noTrackData: "Pas de données de parcours pour cette étape",
  noElevationData: "Pas de profil de dénivelé pour cette étape",
  stepStart: "Départ de {city}",
  stepFinish: "Arrivée à {city}",
  durationFormat: "{hours}h{minutes}",
  elevationProfileLabel: "Profil de dénivelé",
  stepTrackLabel: "Parcours",
  elevationTick: "{elevation}km",
  weatherForecastLabel: "Prévisions météo",
  weatherLabel: "Données météo",
  noWeatherData: "Pas de données météo",
  planned: "À venir",
  in_progress: "En cours",
  completed: "Terminée",
  atDeparture: "Au départ",
  onRoute: "À mi-chemin",
  onArrival: "À l'arrivée",
  temperatureLabel: "Temp :",
  temperature: "{value} °C",
  windLabel: "Vent :",
  wind: "{value} km/h",
  weatherSunny: "Ensoleillé",
  weatherClear: "Dégagé",
  weatherMainlySunny: "Principalement ensoleillé",
  weatherMainlyClear: "Principalement dégagé",
  weatherPartlyCloudy: "Partiellement nuageux",
  weatherCloudy: "Nuageux",
  weatherFoggy: "Brumeux",
  weatherRimeFog: "Brouillard givrant",
  weatherLightDrizzle: "Légère bruine",
  weatherDrizzle: "Bruine",
  weatherHeavyDrizzle: "Forte bruine",
  weatherLightFreezingDrizzle: "Légère bruine verglaçante",
  weatherFreezingDrizzle: "Bruine verglaçante",
  weatherLightRain: "Légère pluie",
  weatherRain: "Pluie",
  weatherHeavyRain: "Forte pluie",
  weatherLightFreezingRain: "Légère pluie verglaçante",
  weatherFreezingRain: "Pluie verglaçante",
  weatherLightSnow: "Légère neige",
  weatherSnow: "Neige",
  weatherHeavySnow: "Forte neige",
  weatherSnowGrains: "Grains de neige",
  weatherLightShowers: "Légères averses",
  weatherShowers: "Averses",
  weatherHeavyShowers: "Forte averses",
  weatherLightSnowShowers: "Légères averses de neige",
  weatherSnowShowers: "Averses de neige",
  weatherThunderstorm: "Orage",
  weatherLightThunderstormsWithHail: "Orages légers avec grêle",
  weatherThunderstormWithHail: "Orage avec grêle",
  contact: "Me contacter",
  plannedExplanation:
    "Lorsque l'étape sera en cours, vous pourrez suivre le parcours en direct !",
  notFoundTitle: "Page non trouvée",
  notFoundSubtitle: "La page que vous cherchez n'existe pas",
  backToHome: "Retour à l'accueil",
  goToCurrentStep: "Suivre l'étape en cours",
  currentStepTitle: "Étape en cours",
  inProgressExplanation: "Suivez le parcours en direct en cliquant ici !",
  liveFollowLabel: "Suivi de la position en <chip>LIVE</chip>",
};
