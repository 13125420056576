import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export const NotFound: FC<{}> = () => {
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      flex={1}
    >
      <Typography variant="h1">
        <FormattedMessage id="notFoundTitle" />
      </Typography>
      <Typography variant="h5">
        <FormattedMessage id="notFoundSubtitle" />
      </Typography>
      <Link to="/">
        <Typography variant="h5">
          <FormattedMessage id="backToHome" />
        </Typography>
      </Link>
    </Stack>
  );
};
