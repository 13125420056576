import { Close } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Fab,
  Stack,
  Typography,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { FC, useState } from "react";
import L, { LatLngTuple } from "leaflet";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { Link } from "react-router-dom";
import {
  TrackPointsApi,
  TripStepTrackPoint,
  TripStepsApi,
} from "../api/backend";
import { TripStartCountdown } from "../components/shared/TripStartCountdown";
import { useError } from "../components/wrappers/GlobalMessageProvider";

export const Project: FC<{}> = () => {
  const [tracks, setTracks] = useState<TripStepTrackPoint[][]>([]);

  const fetchTrack = async (stepId: number) => {
    const api = new TrackPointsApi();
    return api.tripstepTrackPointsList({ tripStepId: stepId });
  };

  const fetchStep = async (stepIndex: number) => {
    const api = new TripStepsApi();
    return api.tripstepsByindexRead({ index: stepIndex });
  };

  const error = useError();

  const fetchStepIds: Promise<number[]> = Promise.all(
    Array.from({ length: 10 }, (_, i) => i).map((index) =>
      fetchStep(index).then((step) => step.id as number)
    )
  );

  const fetchAllTracks = async (stepIds: number[]) => {
    return Promise.all(
      stepIds.map((stepId) =>
        fetchTrack(stepId).then((track) => track as TripStepTrackPoint[])
      )
    );
  };

  const onCircuitAccordionChange = async (e: any, expanded: boolean) => {
    if (expanded) {
      fetchStepIds.then((stepIds) =>
        fetchAllTracks(stepIds).then((tracks) => setTracks(tracks))
      );
    }
  };

  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="space-between"
      flex={1}
    >
      <Link to="/" style={{ position: "fixed", right: 20 }}>
        <Fab size="small" color="primary">
          <Close />
        </Fab>
      </Link>
      <Typography variant="h4" sx={{ marginBottom: 5 }}>
        Le projet
      </Typography>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">L'objectif</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Relier Paris à Marseille en vélo en 10 jours max
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion onChange={onCircuitAccordionChange}>
        <AccordionSummary>
          <Typography variant="h5">Le parcours en bref</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <MapContainer
            style={{ height: 300, width: "90vw" }}
            center={[45.75, 4.85]}
            zoom={5}
            scrollWheelZoom={false}
          >
            <FullscreenControl position="topright" />
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            <Marker
              icon={L.icon({
                iconUrl: "bike-pin-start.svg",
                iconSize: [32, 32],
                iconAnchor: [16, 32],
              })}
              position={
                tracks.length > 0
                  ? [
                      tracks[0][0].trackPointLatitude,
                      tracks[0][0].trackPointLongitude,
                    ]
                  : [48.8534, 2.3488]
              }
            />
            <Marker
              icon={L.icon({
                iconUrl: "bike-pin-finish.svg",
                iconSize: [32, 32],
                iconAnchor: [16, 32],
              })}
              position={
                tracks.length > 0
                  ? [
                      tracks[tracks.length - 1][
                        tracks[tracks.length - 1].length - 1
                      ].trackPointLatitude,
                      tracks[tracks.length - 1][
                        tracks[tracks.length - 1].length - 1
                      ].trackPointLongitude,
                    ]
                  : [43.2965, 5.3698]
              }
            />
            {tracks.slice(1, 10).map((track, index) => (
              <Marker
                key={index}
                position={[
                  track[0].trackPointLatitude,
                  track[0].trackPointLongitude,
                ]}
                icon={L.icon({
                  iconUrl: "bike-pin-step.svg",
                  iconSize: [32, 32],
                  iconAnchor: [16, 32],
                })}
              />
            ))}
            {tracks.map((track, index) => (
              <Polyline
                key={index}
                positions={track.map((point) => [
                  point.trackPointLatitude,
                  point.trackPointLongitude,
                ])}
              />
            ))}
          </MapContainer>
          <Link to="/steps">Voir le parcours étape par étape</Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Les dates</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">Du 28 avril au 7 mai 2024</Typography>
          <TripStartCountdown />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Le point de départ</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Paris - Notre Dame : le kilomètre zéro des routes de France !
          </Typography>
          <img src="notre_dame.webp" width="100%" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Le point d'arrivée</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Marseille - La Vieux Port : point d'arrivée de la flamme olympique
            des JO 2024 le 8 mai !
          </Typography>
          <img src="vieux_port.webp" width="100%" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Les étapes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            10 jours = 10 étapes de 85km en moyenne
          </Typography>
          <Link to="/steps">Voir les étapes</Link>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Le vélo</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Un VTT refait à neuf en début d'année
          </Typography>
          <img src="bike.jpg" width="100%" />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Les équipements</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            <ul>
              <li>Un casque</li>
              <li>Des gants</li>
              <li>Un sac à dos</li>
              <li>Des vêtements de pluie</li>
            </ul>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Les hébergements</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Au niveau des hébergements, j'ai choisi de séjourner dans hôtels et
            chambres d'hôtes réservés via Booking et Airbnb
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Les repas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Pour les repas, je compte sur les restaurants et les supermarchés
            des villes traversées
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary>
          <Typography variant="h5">Les frais</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1">
            Au total, je m'en sors pour environ 600 € comprenant l'ensemble des
            réservations d'hôtels/chambres d'hôtes ainsi que le billet de train
            retour vers Paris.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};
