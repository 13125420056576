/* tslint:disable */
/* eslint-disable */
/**
 * BikeTrip API
 * API for BikeTrip
 *
 * The version of the OpenAPI document: v1
 * Contact: lalou_68@hotmail.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TripStepElevationProfile,
  TripStepTrackPoint,
} from '../models/index';
import {
    TripStepElevationProfileFromJSON,
    TripStepElevationProfileToJSON,
    TripStepTrackPointFromJSON,
    TripStepTrackPointToJSON,
} from '../models/index';

export interface TripstepElevationProfileListRequest {
    tripStepId: number;
}

export interface TripstepTrackPointsListRequest {
    tripStepId: number;
}

export interface UploadGpxCreateRequest {
    tripStepId: number;
    file?: Blob;
}

/**
 * 
 */
export class TrackPointsApi extends runtime.BaseAPI {

    /**
     * Get the elevation profile of a given trip step
     * Get the elevation profile of a given trip step
     */
    async tripstepElevationProfileListRaw(requestParameters: TripstepElevationProfileListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TripStepElevationProfile>>> {
        if (requestParameters['tripStepId'] == null) {
            throw new runtime.RequiredError(
                'tripStepId',
                'Required parameter "tripStepId" was null or undefined when calling tripstepElevationProfileList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/tripstep/elevation-profile/{trip_step_id}`.replace(`{${"trip_step_id"}}`, encodeURIComponent(String(requestParameters['tripStepId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TripStepElevationProfileFromJSON));
    }

    /**
     * Get the elevation profile of a given trip step
     * Get the elevation profile of a given trip step
     */
    async tripstepElevationProfileList(requestParameters: TripstepElevationProfileListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TripStepElevationProfile>> {
        const response = await this.tripstepElevationProfileListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of all track points
     * Get the list of track points
     */
    async tripstepTrackPointsListRaw(requestParameters: TripstepTrackPointsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TripStepTrackPoint>>> {
        if (requestParameters['tripStepId'] == null) {
            throw new runtime.RequiredError(
                'tripStepId',
                'Required parameter "tripStepId" was null or undefined when calling tripstepTrackPointsList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/tripstep/track-points/{trip_step_id}`.replace(`{${"trip_step_id"}}`, encodeURIComponent(String(requestParameters['tripStepId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TripStepTrackPointFromJSON));
    }

    /**
     * Get the list of all track points
     * Get the list of track points
     */
    async tripstepTrackPointsList(requestParameters: TripstepTrackPointsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TripStepTrackPoint>> {
        const response = await this.tripstepTrackPointsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a GPX file for a given trip step
     * Upload a GPX file for a given trip step
     */
    async uploadGpxCreateRaw(requestParameters: UploadGpxCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['tripStepId'] == null) {
            throw new runtime.RequiredError(
                'tripStepId',
                'Required parameter "tripStepId" was null or undefined when calling uploadGpxCreate().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters['file'] != null) {
            formParams.append('file', requestParameters['file'] as any);
        }

        const response = await this.request({
            path: `/upload-gpx/{trip_step_id}`.replace(`{${"trip_step_id"}}`, encodeURIComponent(String(requestParameters['tripStepId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload a GPX file for a given trip step
     * Upload a GPX file for a given trip step
     */
    async uploadGpxCreate(requestParameters: UploadGpxCreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.uploadGpxCreateRaw(requestParameters, initOverrides);
    }

}
