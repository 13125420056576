import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface CountdownProps {
  targetDatetime: string;
}

const Countdown: React.FC<CountdownProps> = ({ targetDatetime }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDatetime) - +new Date();
    let timeLeft: any = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  const CIRCULAR_PROGRESS_SIZE = 70;

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  return (
    <Grid container spacing={3} justifyContent="center" alignItems="center">
      <Grid item xs={3} textAlign="center">
        <Box display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress
            variant="determinate"
            value={(timeLeft.days / 7) * 100}
            size={CIRCULAR_PROGRESS_SIZE}
            thickness={5}
          />
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' position='absolute'>
            <Typography variant='h5'>
              <FormattedMessage id="days" values={{ count: timeLeft.days }} />
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <Box display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress
            variant="determinate"
            value={(timeLeft.hours / 24) * 100}
            size={CIRCULAR_PROGRESS_SIZE}
            thickness={5}
          />
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' position='absolute'>
            <Typography variant='h5'>
              <FormattedMessage id="hours" values={{ count: timeLeft.hours }} />
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <Box display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress
            variant="determinate"
            value={(timeLeft.minutes / 60) * 100}
            size={CIRCULAR_PROGRESS_SIZE}
            thickness={5}
          />
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' position='absolute'>
            <Typography variant='h5'>
              <FormattedMessage id="minutes" values={{ count: timeLeft.minutes }} />
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={3} textAlign="center">
        <Box display='flex' justifyContent='center' alignItems='center'>
          <CircularProgress
            variant="determinate"
            value={(timeLeft.seconds / 60) * 100}
            size={CIRCULAR_PROGRESS_SIZE}
            thickness={5}
          />
          <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' position='absolute'>
            <Typography variant='h5'>
              <FormattedMessage id="seconds" values={{ count: timeLeft.seconds }} />
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Countdown;
