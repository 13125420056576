import {
  ArrowDropDown,
  ArrowRightAlt,
  Close,
  HourglassEmpty,
  LiveTv,
  Route,
  SwapVert,
  Wifi,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Fab,
  Icon,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from "react-intl";
import { Styles } from "../types/Styles";
import { FrenchTownSign } from "../components/shared/FrenchTownSign";
import { ElevationProfile } from "../components/step/ElevationProfile";
import {
  StatusSlug,
  StepStatusIndicator,
} from "../components/step/StepStatusIndicator";
import { StepTrack } from "../components/step/StepTrack";
import { WeatherForecast } from "../components/step/WeatherForecast";
import { CurrentStepContext } from "../App";
import { Link } from "react-router-dom";

export const CurrentStep: FC<{}> = () => {
  const step = useContext(CurrentStepContext);

  const intl = useIntl();

  const formatDuration = (duration: string): string => {
    const [hours, minutes] = duration.split(":");
    return intl.formatMessage({ id: "durationFormat" }, { hours, minutes });
  };

  const getAdjustedDateTime = (datetime: Date, hours: number): string => {
    const date = new Date(datetime);
    date.setHours(hours);
    return date.toISOString();
  };

  return step ? (
    <Stack
      sx={StepStyles.container}
      justifyContent="space-between"
      alignItems="center"
      height="100%"
    >
      <Link to="/" style={{ position: "fixed", right: 20 }}>
        <Fab size="small" color="primary">
          <Close />
        </Fab>
      </Link>
      <Stack gap={1}>
        <Typography variant="h4" fontWeight="bold">
          <FormattedMessage id="currentStepTitle" />
        </Typography>
        <Stack direction="row" width="100%" justifyContent="center">
          <Chip
            sx={StepStyles.date}
            label={
              <FormattedDate
                value={step.stepDate}
                weekday="long"
                day="numeric"
                month="long"
                year="numeric"
              />
            }
            variant="outlined"
          />
          <StepStatusIndicator
            status={step.stepStatus.statusSlug as StatusSlug}
          />
        </Stack>
        <Stack
          direction="row"
          sx={StepStyles.cities}
          alignItems="center"
          justifyContent="center"
        >
          <FrenchTownSign city={step.stepStartCity} />
          <Icon component={ArrowRightAlt} />
          <FrenchTownSign city={step.stepEndCity} />
        </Stack>
      </Stack>
      {step.id && step.stepLiveTrackingUrl && (
        <Accordion defaultExpanded sx={{ width: "95vw" }}>
          <AccordionSummary expandIcon={<Icon component={ArrowDropDown} />}>
            <Typography variant="h6">
              <FormattedMessage
                id="liveFollowLabel"
                values={{
                  chip: (chunks) => (
                    <Chip
                      label={chunks}
                      variant="outlined"
                      sx={{ backgroundColor: "orange" }}
                      icon={<Wifi />}
                    />
                  ),
                }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Link to={step.stepLiveTrackingUrl} target="_blank">
              <Button variant="contained" color="primary" endIcon={<LiveTv />}>
                <FormattedMessage id="goToCurrentStep" />
              </Button>
            </Link>
          </AccordionDetails>
        </Accordion>
      )}
      {step.id && (
        <Accordion sx={{ width: "95vw" }}>
          <AccordionSummary expandIcon={<Icon component={ArrowDropDown} />}>
            <Typography variant="h6">
              <FormattedMessage id="elevationProfileLabel" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ display: "flex", justifyContent: "center" }}>
            <ElevationProfile stepId={step.id} height={150} width={400} />
          </AccordionDetails>
        </Accordion>
      )}
      {
        <Accordion sx={{ width: "95vw" }} defaultExpanded>
          <AccordionSummary expandIcon={<Icon component={ArrowDropDown} />}>
            <Typography variant="h6">
              <FormattedMessage id="weatherLabel" />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction="row"
              justifyContent="space-between"
              overflow="scroll"
            >
              {step.stepFirstTrackPoint && (
                <WeatherForecast
                  label={intl.formatMessage({ id: "atDeparture" })}
                  latitude={step.stepFirstTrackPoint.trackPointLatitude}
                  longitude={step.stepFirstTrackPoint.trackPointLongitude}
                  datetime={getAdjustedDateTime(step.stepDate, 10)}
                  routeDirection={step.stepRouteDirection}
                />
              )}
              {step.stepMiddleTrackPoint && (
                <WeatherForecast
                  label={intl.formatMessage({ id: "onRoute" })}
                  latitude={step.stepFirstTrackPoint.trackPointLatitude}
                  longitude={step.stepFirstTrackPoint.trackPointLongitude}
                  datetime={getAdjustedDateTime(step.stepDate, 14)}
                  routeDirection={step.stepRouteDirection}
                />
              )}
              {step.stepLastTrackPoint && (
                <WeatherForecast
                  label={intl.formatMessage({ id: "onArrival" })}
                  latitude={step.stepFirstTrackPoint.trackPointLatitude}
                  longitude={step.stepFirstTrackPoint.trackPointLongitude}
                  datetime={getAdjustedDateTime(step.stepDate, 18)}
                  routeDirection={step.stepRouteDirection}
                />
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
      }
      <Stack direction="row" justifyContent="space-evenly" width="100%">
        <Box>
          <Route />
          <Typography variant="h6">
            <FormattedNumber
              value={step.stepDistance / 1000}
              style="unit"
              unit="kilometer"
              maximumFractionDigits={2}
            />
          </Typography>
        </Box>
        <Box>
          <HourglassEmpty />
          {step.stepPlannedDuration && (
            <Typography variant="h6">
              <FormattedMessage
                id="duration"
                values={{
                  duration: formatDuration(step.stepPlannedDuration),
                  b: (chunks) => <b>{chunks}</b>,
                }}
              />
            </Typography>
          )}
        </Box>
        <Box>
          <SwapVert />
          <Typography variant="h6">
            <FormattedMessage
              id="elevation"
              values={{
                positive: step.stepPositiveElevation,
                negative: step.stepNegativeElevation,
                b: (chunks) => <b>{chunks}</b>,
              }}
            />
          </Typography>
        </Box>
      </Stack>
    </Stack>
  ) : (
    <StepSkeleton />
  );
};

const StepSkeleton: FC = () => (
  <Stack sx={StepStyles.container}>
    <Skeleton variant="text" width="100%" height={40} />
    <Skeleton variant="text" width="100%" height={20} />
    <Stack
      direction="row"
      sx={StepStyles.cities}
      alignItems="center"
      justifyContent="center"
    >
      <Skeleton variant="circular" width={40} height={40} />
      <Icon component={ArrowRightAlt} />
      <Skeleton variant="circular" width={40} height={40} />
    </Stack>
  </Stack>
);

const StepStyles: Styles = {
  container: {
    width: "100vw",
    paddingTop: 2,
    backgroundColor: "#ffc273",
  },
  date: {
    marginRight: 5,
  },
};
