import { Stack, Typography, useTheme } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import {
  V1ForecastGetHourlyEnum,
  WeatherForecastApi,
} from "../../api/open-meteo";
import weatherCodes from "../../constants/weatherCodes.json";
import { WindDirection } from "./WindDirection";

interface WeatherForecastProps {
  label: string;
  datetime: string;
  latitude: number;
  longitude: number;
  routeDirection?: number;
}

interface Weather {
  temperature: number;
  windDirection: number;
  windSpeed: number;
  weatherCode: number;
}

export const WeatherForecast: FC<WeatherForecastProps> = ({
  label,
  datetime,
  latitude,
  longitude,
  routeDirection,
}) => {
  const theme = useTheme();

  const [weather, setWeather] = useState<Weather>();

  const fetchWeather = async (
    datetime: string,
    latitude: number,
    longitude: number
  ) => {
    const startDate = new Date(datetime);
    const endDate = new Date(startDate);
    const api = new WeatherForecastApi();
    return api.v1ForecastGet({
      startDate: startDate,
      endDate: endDate,
      latitude,
      longitude,
      hourly: [
        V1ForecastGetHourlyEnum.Temperature2m,
        V1ForecastGetHourlyEnum.WindDirection10m,
        V1ForecastGetHourlyEnum.WindSpeed10m,
        V1ForecastGetHourlyEnum.WeatherCode,
      ],
    });
  };

  useEffect(() => {
    fetchWeather(datetime, latitude, longitude).then((response) => {
      try {
        const indexOfDateTime =
          response.hourly?.time?.findIndex(
            (dt) => new Date(dt).getTime() === new Date(datetime).getTime()
          ) ?? -1;
        const temperature = response.hourly?.temperature2m?.[indexOfDateTime];
        const windDirection =
          response.hourly?.windDirection10m?.[indexOfDateTime];
        const windSpeed = response.hourly?.windSpeed10m?.[indexOfDateTime];
        const weatherCode = response.hourly?.weatherCode?.[indexOfDateTime];
        if (temperature && windDirection && windSpeed && weatherCode) {
          setWeather({
            temperature: temperature,
            windDirection: windDirection,
            windSpeed: windSpeed,
            weatherCode: weatherCode,
          });
        }
      } catch (error) {
        console.error(error);
      }
    });
  }, [datetime, latitude, longitude]);

  if (!weather) {
    return (
      <Stack direction="row" justifyContent="space-between">
        <Typography>
          <FormattedMessage id="noWeatherData" />
        </Typography>
      </Stack>
    );
  }

  const weatherDescription =
    weatherCodes[weather.weatherCode.toString() as keyof typeof weatherCodes];

  return (
    <Stack
      direction="column"
      flex={1}
      sx={{
        border: "1px solid black",
        borderRadius: 1,
        marginLeft: 1,
        marginRight: 1,
        padding: 1,
        gap: 1,
        minWidth: 150,
      }}
    >
      <Typography fontWeight="bold">{label}</Typography>
      <Stack direction="column" gap={1}>
        <Stack direction="row" justifyContent="space-around">
          <img
            src={weatherDescription.day.image}
            alt="weather icon"
            height={32}
            width={32}
            style={{
              backgroundColor: theme.palette.primary.main,
              borderRadius: 16,
              marginRight: 5,
            }}
          />
          <Typography sx={{ lineHeight: 1 }}>
            <FormattedMessage id={weatherDescription.day.id} />
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography>
            <FormattedMessage id="temperatureLabel" />
          </Typography>
          <Typography>
            <FormattedMessage
              id="temperature"
              values={{ value: weather?.temperature }}
            />
          </Typography>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Typography>
            <FormattedMessage id="windLabel" />
          </Typography>
          <Stack direction="column">
            <Typography>
              <FormattedMessage
                id="wind"
                values={{ value: weather?.windSpeed }}
              />
            </Typography>
            {routeDirection && (
              <WindDirection
                windDirection={weather.windDirection}
                routeDirection={routeDirection}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
