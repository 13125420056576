/* tslint:disable */
/* eslint-disable */
/**
 * Open-Meteo APIs
 * Open-Meteo offers free weather forecast APIs for open-source developers and non-commercial use. No API key is required.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@open-meteo.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CurrentWeather } from './CurrentWeather';
import {
    CurrentWeatherFromJSON,
    CurrentWeatherFromJSONTyped,
    CurrentWeatherToJSON,
} from './CurrentWeather';
import type { DailyResponse } from './DailyResponse';
import {
    DailyResponseFromJSON,
    DailyResponseFromJSONTyped,
    DailyResponseToJSON,
} from './DailyResponse';
import type { HourlyResponse } from './HourlyResponse';
import {
    HourlyResponseFromJSON,
    HourlyResponseFromJSONTyped,
    HourlyResponseToJSON,
} from './HourlyResponse';

/**
 * 
 * @export
 * @interface V1ForecastGet200Response
 */
export interface V1ForecastGet200Response {
    /**
     * WGS84 of the center of the weather grid-cell which was used to generate this forecast. This coordinate might be up to 5 km away.
     * @type {number}
     * @memberof V1ForecastGet200Response
     */
    latitude?: number;
    /**
     * WGS84 of the center of the weather grid-cell which was used to generate this forecast. This coordinate might be up to 5 km away.
     * @type {number}
     * @memberof V1ForecastGet200Response
     */
    longitude?: number;
    /**
     * The elevation in meters of the selected weather grid-cell. In mountain terrain it might differ from the location you would expect.
     * @type {number}
     * @memberof V1ForecastGet200Response
     */
    elevation?: number;
    /**
     * Generation time of the weather forecast in milli seconds. This is mainly used for performance monitoring and improvements.
     * @type {number}
     * @memberof V1ForecastGet200Response
     */
    generationtimeMs?: number;
    /**
     * Applied timezone offset from the &timezone= parameter.
     * @type {number}
     * @memberof V1ForecastGet200Response
     */
    utcOffsetSeconds?: number;
    /**
     * For each selected weather variable, data will be returned as a floating point array. Additionally a `time` array will be returned with ISO8601 timestamps.
     * @type {HourlyResponse}
     * @memberof V1ForecastGet200Response
     */
    hourly?: HourlyResponse;
    /**
     * For each selected weather variable, the unit will be listed here.
     * @type {{ [key: string]: string; }}
     * @memberof V1ForecastGet200Response
     */
    hourlyUnits?: { [key: string]: string; };
    /**
     * For each selected daily weather variable, data will be returned as a floating point array. Additionally a `time` array will be returned with ISO8601 timestamps.
     * @type {DailyResponse}
     * @memberof V1ForecastGet200Response
     */
    daily?: DailyResponse;
    /**
     * For each selected daily weather variable, the unit will be listed here.
     * @type {{ [key: string]: string; }}
     * @memberof V1ForecastGet200Response
     */
    dailyUnits?: { [key: string]: string; };
    /**
     * Current weather conditions with the attributes: time, temperature, wind_speed, wind_direction and weather_code
     * @type {CurrentWeather}
     * @memberof V1ForecastGet200Response
     */
    currentWeather?: CurrentWeather;
}

/**
 * Check if a given object implements the V1ForecastGet200Response interface.
 */
export function instanceOfV1ForecastGet200Response(value: object): boolean {
    return true;
}

export function V1ForecastGet200ResponseFromJSON(json: any): V1ForecastGet200Response {
    return V1ForecastGet200ResponseFromJSONTyped(json, false);
}

export function V1ForecastGet200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): V1ForecastGet200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'latitude': json['latitude'] == null ? undefined : json['latitude'],
        'longitude': json['longitude'] == null ? undefined : json['longitude'],
        'elevation': json['elevation'] == null ? undefined : json['elevation'],
        'generationtimeMs': json['generationtime_ms'] == null ? undefined : json['generationtime_ms'],
        'utcOffsetSeconds': json['utc_offset_seconds'] == null ? undefined : json['utc_offset_seconds'],
        'hourly': json['hourly'] == null ? undefined : HourlyResponseFromJSON(json['hourly']),
        'hourlyUnits': json['hourly_units'] == null ? undefined : json['hourly_units'],
        'daily': json['daily'] == null ? undefined : DailyResponseFromJSON(json['daily']),
        'dailyUnits': json['daily_units'] == null ? undefined : json['daily_units'],
        'currentWeather': json['current_weather'] == null ? undefined : CurrentWeatherFromJSON(json['current_weather']),
    };
}

export function V1ForecastGet200ResponseToJSON(value?: V1ForecastGet200Response | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'elevation': value['elevation'],
        'generationtime_ms': value['generationtimeMs'],
        'utc_offset_seconds': value['utcOffsetSeconds'],
        'hourly': HourlyResponseToJSON(value['hourly']),
        'hourly_units': value['hourlyUnits'],
        'daily': DailyResponseToJSON(value['daily']),
        'daily_units': value['dailyUnits'],
        'current_weather': CurrentWeatherToJSON(value['currentWeather']),
    };
}

