/* tslint:disable */
/* eslint-disable */
/**
 * Open-Meteo APIs
 * Open-Meteo offers free weather forecast APIs for open-source developers and non-commercial use. No API key is required.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@open-meteo.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  V1ForecastGet200Response,
  V1ForecastGet400Response,
} from '../models/index';
import {
    V1ForecastGet200ResponseFromJSON,
    V1ForecastGet200ResponseToJSON,
    V1ForecastGet400ResponseFromJSON,
    V1ForecastGet400ResponseToJSON,
} from '../models/index';

export interface V1ForecastGetRequest {
    latitude: number;
    longitude: number;
    hourly?: Array<V1ForecastGetHourlyEnum>;
    daily?: Array<V1ForecastGetDailyEnum>;
    startDate?: Date;
    endDate?: Date;
    currentWeather?: boolean;
    temperatureUnit?: V1ForecastGetTemperatureUnitEnum;
    windSpeedUnit?: V1ForecastGetWindSpeedUnitEnum;
    timeformat?: V1ForecastGetTimeformatEnum;
    timezone?: string;
    pastDays?: V1ForecastGetPastDaysEnum;
}

/**
 * 
 */
export class WeatherForecastApi extends runtime.BaseAPI {

    /**
     * 7 day weather variables in hourly and daily resolution for given WGS84 latitude and longitude coordinates. Available worldwide.
     * 7 day weather forecast for coordinates
     */
    async v1ForecastGetRaw(requestParameters: V1ForecastGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<V1ForecastGet200Response>> {
        if (requestParameters['latitude'] == null) {
            throw new runtime.RequiredError(
                'latitude',
                'Required parameter "latitude" was null or undefined when calling v1ForecastGet().'
            );
        }

        if (requestParameters['longitude'] == null) {
            throw new runtime.RequiredError(
                'longitude',
                'Required parameter "longitude" was null or undefined when calling v1ForecastGet().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['hourly'] != null) {
            queryParameters['hourly'] = requestParameters['hourly']!.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        if (requestParameters['daily'] != null) {
            queryParameters['daily'] = requestParameters['daily'];
        }

        if (requestParameters['latitude'] != null) {
            queryParameters['latitude'] = requestParameters['latitude'];
        }

        if (requestParameters['longitude'] != null) {
            queryParameters['longitude'] = requestParameters['longitude'];
        }

        if (requestParameters['startDate'] != null) {
            queryParameters['start_date'] = (requestParameters['startDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['endDate'] != null) {
            queryParameters['end_date'] = (requestParameters['endDate'] as any).toISOString().substring(0,10);
        }

        if (requestParameters['currentWeather'] != null) {
            queryParameters['current_weather'] = requestParameters['currentWeather'];
        }

        if (requestParameters['temperatureUnit'] != null) {
            queryParameters['temperature_unit'] = requestParameters['temperatureUnit'];
        }

        if (requestParameters['windSpeedUnit'] != null) {
            queryParameters['wind_speed_unit'] = requestParameters['windSpeedUnit'];
        }

        if (requestParameters['timeformat'] != null) {
            queryParameters['timeformat'] = requestParameters['timeformat'];
        }

        if (requestParameters['timezone'] != null) {
            queryParameters['timezone'] = requestParameters['timezone'];
        }

        if (requestParameters['pastDays'] != null) {
            queryParameters['past_days'] = requestParameters['pastDays'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v1/forecast`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => V1ForecastGet200ResponseFromJSON(jsonValue));
    }

    /**
     * 7 day weather variables in hourly and daily resolution for given WGS84 latitude and longitude coordinates. Available worldwide.
     * 7 day weather forecast for coordinates
     */
    async v1ForecastGet(requestParameters: V1ForecastGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<V1ForecastGet200Response> {
        const response = await this.v1ForecastGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const V1ForecastGetHourlyEnum = {
    Temperature2m: 'temperature_2m',
    RelativeHumidity2m: 'relative_humidity_2m',
    DewPoint2m: 'dew_point_2m',
    ApparentTemperature: 'apparent_temperature',
    PressureMsl: 'pressure_msl',
    CloudCover: 'cloud_cover',
    CloudCoverLow: 'cloud_cover_low',
    CloudCoverMid: 'cloud_cover_mid',
    CloudCoverHigh: 'cloud_cover_high',
    WindSpeed10m: 'wind_speed_10m',
    WindSpeed80m: 'wind_speed_80m',
    WindSpeed120m: 'wind_speed_120m',
    WindSpeed180m: 'wind_speed_180m',
    WindDirection10m: 'wind_direction_10m',
    WindDirection80m: 'wind_direction_80m',
    WindDirection120m: 'wind_direction_120m',
    WindDirection180m: 'wind_direction_180m',
    WindGusts10m: 'wind_gusts_10m',
    ShortwaveRadiation: 'shortwave_radiation',
    DirectRadiation: 'direct_radiation',
    DirectNormalIrradiance: 'direct_normal_irradiance',
    DiffuseRadiation: 'diffuse_radiation',
    VapourPressureDeficit: 'vapour_pressure_deficit',
    Evapotranspiration: 'evapotranspiration',
    Precipitation: 'precipitation',
    WeatherCode: 'weather_code',
    SnowHeight: 'snow_height',
    FreezingLevelHeight: 'freezing_level_height',
    SoilTemperature0cm: 'soil_temperature_0cm',
    SoilTemperature6cm: 'soil_temperature_6cm',
    SoilTemperature18cm: 'soil_temperature_18cm',
    SoilTemperature54cm: 'soil_temperature_54cm',
    SoilMoisture01cm: 'soil_moisture_0_1cm',
    SoilMoisture13cm: 'soil_moisture_1_3cm',
    SoilMoisture39cm: 'soil_moisture_3_9cm',
    SoilMoisture927cm: 'soil_moisture_9_27cm',
    SoilMoisture2781cm: 'soil_moisture_27_81cm'
} as const;
export type V1ForecastGetHourlyEnum = typeof V1ForecastGetHourlyEnum[keyof typeof V1ForecastGetHourlyEnum];
/**
 * @export
 */
export const V1ForecastGetDailyEnum = {
    Temperature2mMax: 'temperature_2m_max',
    Temperature2mMin: 'temperature_2m_min',
    ApparentTemperatureMax: 'apparent_temperature_max',
    ApparentTemperatureMin: 'apparent_temperature_min',
    PrecipitationSum: 'precipitation_sum',
    PrecipitationHours: 'precipitation_hours',
    WeatherCode: 'weather_code',
    Sunrise: 'sunrise',
    Sunset: 'sunset',
    WindSpeed10mMax: 'wind_speed_10m_max',
    WindGusts10mMax: 'wind_gusts_10m_max',
    WindDirection10mDominant: 'wind_direction_10m_dominant',
    ShortwaveRadiationSum: 'shortwave_radiation_sum',
    UvIndexMax: 'uv_index_max',
    UvIndexClearSkyMax: 'uv_index_clear_sky_max',
    Et0FaoEvapotranspiration: 'et0_fao_evapotranspiration'
} as const;
export type V1ForecastGetDailyEnum = typeof V1ForecastGetDailyEnum[keyof typeof V1ForecastGetDailyEnum];
/**
 * @export
 */
export const V1ForecastGetTemperatureUnitEnum = {
    Celsius: 'celsius',
    Fahrenheit: 'fahrenheit'
} as const;
export type V1ForecastGetTemperatureUnitEnum = typeof V1ForecastGetTemperatureUnitEnum[keyof typeof V1ForecastGetTemperatureUnitEnum];
/**
 * @export
 */
export const V1ForecastGetWindSpeedUnitEnum = {
    Kmh: 'kmh',
    Ms: 'ms',
    Mph: 'mph',
    Kn: 'kn'
} as const;
export type V1ForecastGetWindSpeedUnitEnum = typeof V1ForecastGetWindSpeedUnitEnum[keyof typeof V1ForecastGetWindSpeedUnitEnum];
/**
 * @export
 */
export const V1ForecastGetTimeformatEnum = {
    Iso8601: 'iso8601',
    Unixtime: 'unixtime'
} as const;
export type V1ForecastGetTimeformatEnum = typeof V1ForecastGetTimeformatEnum[keyof typeof V1ForecastGetTimeformatEnum];
/**
 * @export
 */
export const V1ForecastGetPastDaysEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type V1ForecastGetPastDaysEnum = typeof V1ForecastGetPastDaysEnum[keyof typeof V1ForecastGetPastDaysEnum];
