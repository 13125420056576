import { Snackbar, Alert } from "@mui/material"
import { FC, createContext, useContext, useState } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { UseState } from "../../types/UseState"

interface GlobalMessageProviderProps {
    children: React.ReactNode
}

interface GlobalMessage {
    message: string
    severity: "error" | "warning" | "info" | "success"
}

export const GlobalMessageContext = createContext<UseState<GlobalMessage | null>>({ value: null, setter: () => { } });

export const GlobalMessageProvider: FC<GlobalMessageProviderProps> = ({ children }) => {

    const [message, setMessage] = useState<GlobalMessage | null>(null);

    return (
        <GlobalMessageContext.Provider value={{ value: message, setter: setMessage }}>
            <Snackbar open={!!message} anchorOrigin={{ horizontal: "center", vertical: "top" }} autoHideDuration={3000} onClose={() => setMessage(null)}>
                <Alert severity="error">
                    <FormattedMessage id={message?.message || "APIError"} />
                </Alert>
            </Snackbar>
            {children}
        </GlobalMessageContext.Provider>
    )
}

export const useError = () => {
    const { setter: setGlobalMessage } = useContext(GlobalMessageContext);
    const intl = useIntl();
    return (errorMessageId ?: string) => setGlobalMessage({ message: intl.formatMessage({ id: errorMessageId || "APIError" }), severity: "error" });
}