/* tslint:disable */
/* eslint-disable */
/**
 * BikeTrip API
 * API for BikeTrip
 *
 * The version of the OpenAPI document: v1
 * Contact: lalou_68@hotmail.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  TripStep,
} from '../models/index';
import {
    TripStepFromJSON,
    TripStepToJSON,
} from '../models/index';

export interface TripstepsByindexReadRequest {
    index: number;
}

/**
 * 
 */
export class TripStepsApi extends runtime.BaseAPI {

    /**
     * Get a trip step by its index after sorting by date
     * Get a trip step by its chronological index
     */
    async tripstepsByindexReadRaw(requestParameters: TripstepsByindexReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TripStep>> {
        if (requestParameters['index'] == null) {
            throw new runtime.RequiredError(
                'index',
                'Required parameter "index" was null or undefined when calling tripstepsByindexRead().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/tripsteps/byindex/{index}`.replace(`{${"index"}}`, encodeURIComponent(String(requestParameters['index']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TripStepFromJSON(jsonValue));
    }

    /**
     * Get a trip step by its index after sorting by date
     * Get a trip step by its chronological index
     */
    async tripstepsByindexRead(requestParameters: TripstepsByindexReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TripStep> {
        const response = await this.tripstepsByindexReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the list of all trip steps
     * Get the list of trip steps
     */
    async tripstepsListRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TripStep>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && (this.configuration.username !== undefined || this.configuration.password !== undefined)) {
            headerParameters["Authorization"] = "Basic " + btoa(this.configuration.username + ":" + this.configuration.password);
        }
        const response = await this.request({
            path: `/tripsteps/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TripStepFromJSON));
    }

    /**
     * Get the list of all trip steps
     * Get the list of trip steps
     */
    async tripstepsList(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TripStep>> {
        const response = await this.tripstepsListRaw(initOverrides);
        return await response.value();
    }

}
