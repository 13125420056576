import { FC, useEffect, useState } from "react";
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from "recharts";
import { useError } from "../wrappers/GlobalMessageProvider";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { Skeleton, Typography, useTheme } from "@mui/material";
import { TripStepElevationProfile } from "../../api/backend/models/TripStepElevationProfile";
import { TrackPointsApi } from "../../api/backend/apis/TrackPointsApi";

interface ElevationProfileProps {
  stepId: number;
  height?: number;
  width?: number;
}

const CustomXAxisTick = (props: any) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-45)"
      >
        <FormattedNumber
          value={payload.value / 1000}
          style="unit"
          unit="kilometer"
          maximumFractionDigits={1}
        />
      </text>
    </g>
  );
};

export const ElevationProfile: FC<ElevationProfileProps> = ({
  stepId,
  height,
  width,
}) => {
  const [profile, setProfile] = useState<TripStepElevationProfile[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const theme = useTheme();

  const DEFAULT_HEIGHT = 200;
  const DEFAULT_WIDTH = 400;

  height = height || DEFAULT_HEIGHT;
  width = width || DEFAULT_WIDTH;

  const fetchProfile = async (stepId: number) => {
    const api = new TrackPointsApi();
    return api.tripstepElevationProfileList({ tripStepId: stepId });
  };

  const error = useError();

  useEffect(() => {
    setIsLoading(true);
    fetchProfile(stepId)
      .then((profile) => {
        setProfile(profile);
        setIsLoading(false);
      })
      .catch((err) => error("APIErrorElevationProfile"));
  }, [stepId]);

  if (isLoading) {
    return <Skeleton variant="rectangular" width={width} height={height} />;
  }
  if (!profile || profile.length === 0) {
    return (
      <Typography variant="h6">
        <FormattedMessage id="noElevationData" />
      </Typography>
    );
  }

  return (
    <LineChart
      data={profile}
      margin={{ top: 5, bottom: 35 }}
      height={height}
      width={width}
    >
      <CartesianGrid stroke="#f5f5f5" />
      <XAxis dataKey="trackPointMeter" tick={<CustomXAxisTick />} />
      <YAxis />
      <Line
        type="monotone"
        dataKey="trackPointElevation"
        dot={false}
        strokeWidth={2}
        stroke={theme.palette.primary.main}
      />
    </LineChart>
  );
};
