/* tslint:disable */
/* eslint-disable */
/**
 * BikeTrip API
 * API for BikeTrip
 *
 * The version of the OpenAPI document: v1
 * Contact: lalou_68@hotmail.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TripStepStatus } from './TripStepStatus';
import {
    TripStepStatusFromJSON,
    TripStepStatusFromJSONTyped,
    TripStepStatusToJSON,
} from './TripStepStatus';
import type { TripStepTrackPoint } from './TripStepTrackPoint';
import {
    TripStepTrackPointFromJSON,
    TripStepTrackPointFromJSONTyped,
    TripStepTrackPointToJSON,
} from './TripStepTrackPoint';

/**
 * 
 * @export
 * @interface TripStep
 */
export interface TripStep {
    /**
     * 
     * @type {number}
     * @memberof TripStep
     */
    readonly id?: number;
    /**
     * 
     * @type {TripStepStatus}
     * @memberof TripStep
     */
    stepStatus: TripStepStatus;
    /**
     * 
     * @type {TripStepTrackPoint}
     * @memberof TripStep
     */
    stepFirstTrackPoint: TripStepTrackPoint;
    /**
     * 
     * @type {TripStepTrackPoint}
     * @memberof TripStep
     */
    stepMiddleTrackPoint: TripStepTrackPoint;
    /**
     * 
     * @type {TripStepTrackPoint}
     * @memberof TripStep
     */
    stepLastTrackPoint: TripStepTrackPoint;
    /**
     * 
     * @type {string}
     * @memberof TripStep
     */
    stepDescription?: string;
    /**
     * 
     * @type {number}
     * @memberof TripStep
     */
    stepDistance: number;
    /**
     * 
     * @type {Date}
     * @memberof TripStep
     */
    stepDate: Date;
    /**
     * 
     * @type {string}
     * @memberof TripStep
     */
    stepStartCity: string;
    /**
     * 
     * @type {string}
     * @memberof TripStep
     */
    stepEndCity: string;
    /**
     * 
     * @type {number}
     * @memberof TripStep
     */
    stepPositiveElevation: number;
    /**
     * 
     * @type {number}
     * @memberof TripStep
     */
    stepNegativeElevation: number;
    /**
     * 
     * @type {string}
     * @memberof TripStep
     */
    stepPlannedDuration?: string;
    /**
     * 
     * @type {number}
     * @memberof TripStep
     */
    stepRouteDirection?: number;
    /**
     * 
     * @type {string}
     * @memberof TripStep
     */
    stepLiveTrackingUrl?: string;
}

/**
 * Check if a given object implements the TripStep interface.
 */
export function instanceOfTripStep(value: object): boolean {
    if (!('stepStatus' in value)) return false;
    if (!('stepFirstTrackPoint' in value)) return false;
    if (!('stepMiddleTrackPoint' in value)) return false;
    if (!('stepLastTrackPoint' in value)) return false;
    if (!('stepDistance' in value)) return false;
    if (!('stepDate' in value)) return false;
    if (!('stepStartCity' in value)) return false;
    if (!('stepEndCity' in value)) return false;
    if (!('stepPositiveElevation' in value)) return false;
    if (!('stepNegativeElevation' in value)) return false;
    return true;
}

export function TripStepFromJSON(json: any): TripStep {
    return TripStepFromJSONTyped(json, false);
}

export function TripStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripStep {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'stepStatus': TripStepStatusFromJSON(json['step_status']),
        'stepFirstTrackPoint': TripStepTrackPointFromJSON(json['step_first_track_point']),
        'stepMiddleTrackPoint': TripStepTrackPointFromJSON(json['step_middle_track_point']),
        'stepLastTrackPoint': TripStepTrackPointFromJSON(json['step_last_track_point']),
        'stepDescription': json['step_description'] == null ? undefined : json['step_description'],
        'stepDistance': json['step_distance'],
        'stepDate': (new Date(json['step_date'])),
        'stepStartCity': json['step_start_city'],
        'stepEndCity': json['step_end_city'],
        'stepPositiveElevation': json['step_positive_elevation'],
        'stepNegativeElevation': json['step_negative_elevation'],
        'stepPlannedDuration': json['step_planned_duration'] == null ? undefined : json['step_planned_duration'],
        'stepRouteDirection': json['step_route_direction'] == null ? undefined : json['step_route_direction'],
        'stepLiveTrackingUrl': json['step_live_tracking_url'] == null ? undefined : json['step_live_tracking_url'],
    };
}

export function TripStepToJSON(value?: TripStep | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'step_status': TripStepStatusToJSON(value['stepStatus']),
        'step_first_track_point': TripStepTrackPointToJSON(value['stepFirstTrackPoint']),
        'step_middle_track_point': TripStepTrackPointToJSON(value['stepMiddleTrackPoint']),
        'step_last_track_point': TripStepTrackPointToJSON(value['stepLastTrackPoint']),
        'step_description': value['stepDescription'],
        'step_distance': value['stepDistance'],
        'step_date': ((value['stepDate']).toISOString().substring(0,10)),
        'step_start_city': value['stepStartCity'],
        'step_end_city': value['stepEndCity'],
        'step_positive_elevation': value['stepPositiveElevation'],
        'step_negative_elevation': value['stepNegativeElevation'],
        'step_planned_duration': value['stepPlannedDuration'],
        'step_route_direction': value['stepRouteDirection'],
        'step_live_tracking_url': value['stepLiveTrackingUrl'],
    };
}

