import { FC, useContext } from "react";
import { Button, Icon, Stack, Typography } from "@mui/material";
import { ArrowRightAlt } from "@mui/icons-material";
import { FrenchTownSign } from "../components/shared/FrenchTownSign";
import { Styles } from "../types/Styles";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { TripStartCountdown } from "../components/shared/TripStartCountdown";
import { CurrentStepContext } from "../App";

export const Home: FC<{}> = () => {
  const currentStep = useContext(CurrentStepContext);

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
      style={{ position: "relative" }}
    >
      <img
        src="global_map.png"
        alt="Global map"
        style={{
          position: "absolute",
          height: "100%",
          width: "100vw",
          opacity: 0.3,
        }}
      />
      <Stack style={{ position: "relative" }}>
        <Stack
          direction="row"
          sx={HomeStyles.cities}
          alignItems="center"
          justifyContent="center"
        >
          <FrenchTownSign city="Paris" />
          <Icon component={ArrowRightAlt} />
          <FrenchTownSign city="Marseille" />
        </Stack>
        <Typography variant="h3">
          <FormattedMessage id="homeTitle" />
        </Typography>
      </Stack>
      <Stack>
        <Typography variant="h5">
          <FormattedMessage
            id="homeSubtitle"
            values={{ b: (chunks) => <b>{chunks}</b> }}
          />
        </Typography>
        <Typography variant="h5">
          <FormattedMessage
            id="dateAnnouncement"
            values={{ b: (chunks) => <b>{chunks}</b> }}
          />
        </Typography>
        <Typography variant="h5">
          <FormattedMessage id="followMe" />
        </Typography>
      </Stack>
      {currentStep ? (
        <Link to="/current-step">
          <Button
            variant="contained"
            sx={{
              color: "white",
              backgroundColor: "#ED7F10",
            }}
            size="large"
            style={{ marginBottom: 5 }}
          >
            <Stack direction="column" alignItems="center">
              <Typography variant="h5">
                <FormattedMessage id="goToCurrentStep" />
              </Typography>
              <Stack
                direction="row"
                sx={HomeStyles.cities}
                alignItems="center"
                justifyContent="center"
              >
                <FrenchTownSign city={currentStep.stepStartCity} />
                <Icon component={ArrowRightAlt} />
                <FrenchTownSign city={currentStep.stepEndCity} />
              </Stack>
            </Stack>
          </Button>
        </Link>
      ) : (
        <Stack>
          <Typography variant="h4">
            <FormattedMessage id="countdownTitle" />
          </Typography>
          <TripStartCountdown />
        </Stack>
      )}
      <Stack>
        <Typography variant="h4">
          <FormattedMessage id="waiting" />
        </Typography>
        <Link to="/project">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: 5 }}
          >
            <FormattedMessage id="goToDetails" />
          </Button>
        </Link>
        <Link to="/steps">
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{ marginBottom: 5 }}
          >
            <FormattedMessage id="goToSteps" />
          </Button>
        </Link>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            window.location.href =
              "mailto:contact@lycium.fr?subject=Contact%20Paris-Marseille%20à%20vélo";
          }}
        >
          <FormattedMessage id="contact" />
        </Button>
      </Stack>
    </Stack>
  );
};

const HomeStyles: Styles = {
  cities: {
    marginTop: "20px",
    marginBottom: "10px",
  },
};
