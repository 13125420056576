import { Stack } from "@mui/material";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { Styles } from "../../types/Styles";
import { GlobalMessageProvider } from "./GlobalMessageProvider";

export const Layout: FC<{}> = () => {
  return (
    <Stack sx={LayoutStyles.container} direction={"column"}>
      <GlobalMessageProvider>
        <Outlet />
      </GlobalMessageProvider>
    </Stack>
  );
}

const LayoutStyles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    padding: "5px",
    backgroundColor: "#89f0b9"
  },
};