import { ThemeProvider, createTheme } from "@mui/material";
import { createContext, useState } from "react";
import { IntlProvider } from "react-intl";
import "./App.css";
import { Layout } from "./components/wrappers/Layout";
import { fr } from "./i18n/fr";
import { Styles } from "./types/Styles";
import { Home } from "./views/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Steps } from "./views/Steps";
import { Project } from "./views/Project";
import { useEffect } from "react";
import ReactGA from "react-ga4";

import { NotFound } from "./views/NotFound";
import { CurrentStep } from "./views/CurrentStep";
import { TripStep, TripStepsApi } from "./api/backend";

const mainTheme = createTheme({
  palette: {
    primary: {
      main: "#13904d",
    },
    secondary: {
      main: "#0000ff",
    },
  },
  typography: {
    fontFamily: "Arial",
    h4: {
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "1.4rem",
    },
    h6: {
      fontSize: "1rem",
    },
  },
});

ReactGA.initialize("G-Y8SVBE9GJR");

export const CurrentStepContext = createContext<TripStep | undefined>(
  undefined
);

function App() {
  const [locale, setLocale] = useState<string>(navigator.language);

  const [currentStep, setCurrentStep] = useState<TripStep | undefined>(
    undefined
  );

  const fetchSteps = async () => {
    const api = new TripStepsApi();
    return api.tripstepsList();
  };

  useEffect(() => {
    fetchSteps().then((steps) => {
      setCurrentStep(
        steps.find((step) => step.stepStatus.statusSlug === "in_progress")
      );
    });
  }, []);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-Y8SVBE9GJR",
      },
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <div className="App" style={styles.container}>
      <ThemeProvider theme={mainTheme}>
        <CurrentStepContext.Provider value={currentStep}>
          <IntlProvider
            locale={locale}
            messages={locale.startsWith("fr") ? fr : fr}
          >
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route path="/" element={<Home />} />
                  <Route path="*" element={<NotFound />} />
                  <Route path="/steps" element={<Steps />} />
                  <Route path="/current-step" element={<CurrentStep />} />
                  <Route path="/project" element={<Project />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </IntlProvider>
        </CurrentStepContext.Provider>
      </ThemeProvider>
    </div>
  );
}

const styles: Styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100dvh",
  },
};

export default App;
