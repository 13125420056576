import { Fab, Pagination, Stack, Typography } from "@mui/material";
import { FC, useState } from "react";
import { Step } from "../components/step/Step";
import { Close, Route } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export const Steps: FC<{}> = () => {
  const [currentStep, setCurrentStep] = useState<number>(0);
  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="space-between"
      flex={1}
    >
      <Link to="/" style={{ position: "fixed", right: 20 }}>
        <Fab size="small" color="primary">
          <Close />
        </Fab>
      </Link>
      <Step currentStep={currentStep} />
      <Pagination
        count={10}
        page={currentStep + 1}
        onChange={(event, value) => setCurrentStep(value - 1)}
      />
    </Stack>
  );
};
