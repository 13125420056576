/* tslint:disable */
/* eslint-disable */
/**
 * Open-Meteo APIs
 * Open-Meteo offers free weather forecast APIs for open-source developers and non-commercial use. No API key is required.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@open-meteo.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CurrentWeather
 */
export interface CurrentWeather {
    /**
     * 
     * @type {string}
     * @memberof CurrentWeather
     */
    time: string;
    /**
     * 
     * @type {number}
     * @memberof CurrentWeather
     */
    temperature: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentWeather
     */
    windSpeed: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentWeather
     */
    windDirection: number;
    /**
     * 
     * @type {number}
     * @memberof CurrentWeather
     */
    weatherCode: number;
}

/**
 * Check if a given object implements the CurrentWeather interface.
 */
export function instanceOfCurrentWeather(value: object): boolean {
    if (!('time' in value)) return false;
    if (!('temperature' in value)) return false;
    if (!('windSpeed' in value)) return false;
    if (!('windDirection' in value)) return false;
    if (!('weatherCode' in value)) return false;
    return true;
}

export function CurrentWeatherFromJSON(json: any): CurrentWeather {
    return CurrentWeatherFromJSONTyped(json, false);
}

export function CurrentWeatherFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrentWeather {
    if (json == null) {
        return json;
    }
    return {
        
        'time': json['time'],
        'temperature': json['temperature'],
        'windSpeed': json['wind_speed'],
        'windDirection': json['wind_direction'],
        'weatherCode': json['weather_code'],
    };
}

export function CurrentWeatherToJSON(value?: CurrentWeather | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'time': value['time'],
        'temperature': value['temperature'],
        'wind_speed': value['windSpeed'],
        'wind_direction': value['windDirection'],
        'weather_code': value['weatherCode'],
    };
}

