/* tslint:disable */
/* eslint-disable */
/**
 * Open-Meteo APIs
 * Open-Meteo offers free weather forecast APIs for open-source developers and non-commercial use. No API key is required.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@open-meteo.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HourlyResponse
 */
export interface HourlyResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof HourlyResponse
     */
    time: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    temperature2m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    relativeHumidity2m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    dewPoint2m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    apparentTemperature?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    pressureMsl?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    cloudCover?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    cloudCoverLow?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    cloudCoverMid?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    cloudCoverHigh?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windSpeed10m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windSpeed80m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windSpeed120m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windSpeed180m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windDirection10m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windDirection80m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windDirection120m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windDirection180m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    windGusts10m?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    shortwaveRadiation?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    directRadiation?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    directNormalIrradiance?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    diffuseRadiation?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    vapourPressureDeficit?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    evapotranspiration?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    precipitation?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    weatherCode?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    snowHeight?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    freezingLevelHeight?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilTemperature0cm?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilTemperature6cm?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilTemperature18cm?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilTemperature54cm?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilMoisture01cm?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilMoisture13cm?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilMoisture39cm?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilMoisture927cm?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof HourlyResponse
     */
    soilMoisture2781cm?: Array<number>;
}

/**
 * Check if a given object implements the HourlyResponse interface.
 */
export function instanceOfHourlyResponse(value: object): boolean {
    if (!('time' in value)) return false;
    return true;
}

export function HourlyResponseFromJSON(json: any): HourlyResponse {
    return HourlyResponseFromJSONTyped(json, false);
}

export function HourlyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HourlyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'time': json['time'],
        'temperature2m': json['temperature_2m'] == null ? undefined : json['temperature_2m'],
        'relativeHumidity2m': json['relative_humidity_2m'] == null ? undefined : json['relative_humidity_2m'],
        'dewPoint2m': json['dew_point_2m'] == null ? undefined : json['dew_point_2m'],
        'apparentTemperature': json['apparent_temperature'] == null ? undefined : json['apparent_temperature'],
        'pressureMsl': json['pressure_msl'] == null ? undefined : json['pressure_msl'],
        'cloudCover': json['cloud_cover'] == null ? undefined : json['cloud_cover'],
        'cloudCoverLow': json['cloud_cover_low'] == null ? undefined : json['cloud_cover_low'],
        'cloudCoverMid': json['cloud_cover_mid'] == null ? undefined : json['cloud_cover_mid'],
        'cloudCoverHigh': json['cloud_cover_high'] == null ? undefined : json['cloud_cover_high'],
        'windSpeed10m': json['wind_speed_10m'] == null ? undefined : json['wind_speed_10m'],
        'windSpeed80m': json['wind_speed_80m'] == null ? undefined : json['wind_speed_80m'],
        'windSpeed120m': json['wind_speed_120m'] == null ? undefined : json['wind_speed_120m'],
        'windSpeed180m': json['wind_speed_180m'] == null ? undefined : json['wind_speed_180m'],
        'windDirection10m': json['wind_direction_10m'] == null ? undefined : json['wind_direction_10m'],
        'windDirection80m': json['wind_direction_80m'] == null ? undefined : json['wind_direction_80m'],
        'windDirection120m': json['wind_direction_120m'] == null ? undefined : json['wind_direction_120m'],
        'windDirection180m': json['wind_direction_180m'] == null ? undefined : json['wind_direction_180m'],
        'windGusts10m': json['wind_gusts_10m'] == null ? undefined : json['wind_gusts_10m'],
        'shortwaveRadiation': json['shortwave_radiation'] == null ? undefined : json['shortwave_radiation'],
        'directRadiation': json['direct_radiation'] == null ? undefined : json['direct_radiation'],
        'directNormalIrradiance': json['direct_normal_irradiance'] == null ? undefined : json['direct_normal_irradiance'],
        'diffuseRadiation': json['diffuse_radiation'] == null ? undefined : json['diffuse_radiation'],
        'vapourPressureDeficit': json['vapour_pressure_deficit'] == null ? undefined : json['vapour_pressure_deficit'],
        'evapotranspiration': json['evapotranspiration'] == null ? undefined : json['evapotranspiration'],
        'precipitation': json['precipitation'] == null ? undefined : json['precipitation'],
        'weatherCode': json['weather_code'] == null ? undefined : json['weather_code'],
        'snowHeight': json['snow_height'] == null ? undefined : json['snow_height'],
        'freezingLevelHeight': json['freezing_level_height'] == null ? undefined : json['freezing_level_height'],
        'soilTemperature0cm': json['soil_temperature_0cm'] == null ? undefined : json['soil_temperature_0cm'],
        'soilTemperature6cm': json['soil_temperature_6cm'] == null ? undefined : json['soil_temperature_6cm'],
        'soilTemperature18cm': json['soil_temperature_18cm'] == null ? undefined : json['soil_temperature_18cm'],
        'soilTemperature54cm': json['soil_temperature_54cm'] == null ? undefined : json['soil_temperature_54cm'],
        'soilMoisture01cm': json['soil_moisture_0_1cm'] == null ? undefined : json['soil_moisture_0_1cm'],
        'soilMoisture13cm': json['soil_moisture_1_3cm'] == null ? undefined : json['soil_moisture_1_3cm'],
        'soilMoisture39cm': json['soil_moisture_3_9cm'] == null ? undefined : json['soil_moisture_3_9cm'],
        'soilMoisture927cm': json['soil_moisture_9_27cm'] == null ? undefined : json['soil_moisture_9_27cm'],
        'soilMoisture2781cm': json['soil_moisture_27_81cm'] == null ? undefined : json['soil_moisture_27_81cm'],
    };
}

export function HourlyResponseToJSON(value?: HourlyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'time': value['time'],
        'temperature_2m': value['temperature2m'],
        'relative_humidity_2m': value['relativeHumidity2m'],
        'dew_point_2m': value['dewPoint2m'],
        'apparent_temperature': value['apparentTemperature'],
        'pressure_msl': value['pressureMsl'],
        'cloud_cover': value['cloudCover'],
        'cloud_cover_low': value['cloudCoverLow'],
        'cloud_cover_mid': value['cloudCoverMid'],
        'cloud_cover_high': value['cloudCoverHigh'],
        'wind_speed_10m': value['windSpeed10m'],
        'wind_speed_80m': value['windSpeed80m'],
        'wind_speed_120m': value['windSpeed120m'],
        'wind_speed_180m': value['windSpeed180m'],
        'wind_direction_10m': value['windDirection10m'],
        'wind_direction_80m': value['windDirection80m'],
        'wind_direction_120m': value['windDirection120m'],
        'wind_direction_180m': value['windDirection180m'],
        'wind_gusts_10m': value['windGusts10m'],
        'shortwave_radiation': value['shortwaveRadiation'],
        'direct_radiation': value['directRadiation'],
        'direct_normal_irradiance': value['directNormalIrradiance'],
        'diffuse_radiation': value['diffuseRadiation'],
        'vapour_pressure_deficit': value['vapourPressureDeficit'],
        'evapotranspiration': value['evapotranspiration'],
        'precipitation': value['precipitation'],
        'weather_code': value['weatherCode'],
        'snow_height': value['snowHeight'],
        'freezing_level_height': value['freezingLevelHeight'],
        'soil_temperature_0cm': value['soilTemperature0cm'],
        'soil_temperature_6cm': value['soilTemperature6cm'],
        'soil_temperature_18cm': value['soilTemperature18cm'],
        'soil_temperature_54cm': value['soilTemperature54cm'],
        'soil_moisture_0_1cm': value['soilMoisture01cm'],
        'soil_moisture_1_3cm': value['soilMoisture13cm'],
        'soil_moisture_3_9cm': value['soilMoisture39cm'],
        'soil_moisture_9_27cm': value['soilMoisture927cm'],
        'soil_moisture_27_81cm': value['soilMoisture2781cm'],
    };
}

