/* tslint:disable */
/* eslint-disable */
/**
 * BikeTrip API
 * API for BikeTrip
 *
 * The version of the OpenAPI document: v1
 * Contact: lalou_68@hotmail.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TripStepTrackPoint
 */
export interface TripStepTrackPoint {
    /**
     * 
     * @type {number}
     * @memberof TripStepTrackPoint
     */
    readonly id?: number;
    /**
     * 
     * @type {number}
     * @memberof TripStepTrackPoint
     */
    trackPointIndex: number;
    /**
     * 
     * @type {number}
     * @memberof TripStepTrackPoint
     */
    trackPointLatitude: number;
    /**
     * 
     * @type {number}
     * @memberof TripStepTrackPoint
     */
    trackPointLongitude: number;
    /**
     * 
     * @type {number}
     * @memberof TripStepTrackPoint
     */
    trackPointElevation?: number;
    /**
     * 
     * @type {number}
     * @memberof TripStepTrackPoint
     */
    trackPointMeter: number;
    /**
     * 
     * @type {number}
     * @memberof TripStepTrackPoint
     */
    trackPointStep: number;
}

/**
 * Check if a given object implements the TripStepTrackPoint interface.
 */
export function instanceOfTripStepTrackPoint(value: object): boolean {
    if (!('trackPointIndex' in value)) return false;
    if (!('trackPointLatitude' in value)) return false;
    if (!('trackPointLongitude' in value)) return false;
    if (!('trackPointMeter' in value)) return false;
    if (!('trackPointStep' in value)) return false;
    return true;
}

export function TripStepTrackPointFromJSON(json: any): TripStepTrackPoint {
    return TripStepTrackPointFromJSONTyped(json, false);
}

export function TripStepTrackPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripStepTrackPoint {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'trackPointIndex': json['track_point_index'],
        'trackPointLatitude': json['track_point_latitude'],
        'trackPointLongitude': json['track_point_longitude'],
        'trackPointElevation': json['track_point_elevation'] == null ? undefined : json['track_point_elevation'],
        'trackPointMeter': json['track_point_meter'],
        'trackPointStep': json['track_point_step'],
    };
}

export function TripStepTrackPointToJSON(value?: TripStepTrackPoint | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'track_point_index': value['trackPointIndex'],
        'track_point_latitude': value['trackPointLatitude'],
        'track_point_longitude': value['trackPointLongitude'],
        'track_point_elevation': value['trackPointElevation'],
        'track_point_meter': value['trackPointMeter'],
        'track_point_step': value['trackPointStep'],
    };
}

