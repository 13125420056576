/* tslint:disable */
/* eslint-disable */
/**
 * Open-Meteo APIs
 * Open-Meteo offers free weather forecast APIs for open-source developers and non-commercial use. No API key is required.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: info@open-meteo.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DailyResponse
 */
export interface DailyResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof DailyResponse
     */
    time: Array<string>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    temperature2mMax?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    temperature2mMin?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    apparentTemperatureMax?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    apparentTemperatureMin?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    precipitationSum?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    precipitationHours?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    weatherCode?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    sunrise?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    sunset?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    windSpeed10mMax?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    windGusts10mMax?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    windDirection10mDominant?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    shortwaveRadiationSum?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    uvIndexMax?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    uvIndexClearSkyMax?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof DailyResponse
     */
    et0FaoEvapotranspiration?: Array<number>;
}

/**
 * Check if a given object implements the DailyResponse interface.
 */
export function instanceOfDailyResponse(value: object): boolean {
    if (!('time' in value)) return false;
    return true;
}

export function DailyResponseFromJSON(json: any): DailyResponse {
    return DailyResponseFromJSONTyped(json, false);
}

export function DailyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DailyResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'time': json['time'],
        'temperature2mMax': json['temperature_2m_max'] == null ? undefined : json['temperature_2m_max'],
        'temperature2mMin': json['temperature_2m_min'] == null ? undefined : json['temperature_2m_min'],
        'apparentTemperatureMax': json['apparent_temperature_max'] == null ? undefined : json['apparent_temperature_max'],
        'apparentTemperatureMin': json['apparent_temperature_min'] == null ? undefined : json['apparent_temperature_min'],
        'precipitationSum': json['precipitation_sum'] == null ? undefined : json['precipitation_sum'],
        'precipitationHours': json['precipitation_hours'] == null ? undefined : json['precipitation_hours'],
        'weatherCode': json['weather_code'] == null ? undefined : json['weather_code'],
        'sunrise': json['sunrise'] == null ? undefined : json['sunrise'],
        'sunset': json['sunset'] == null ? undefined : json['sunset'],
        'windSpeed10mMax': json['wind_speed_10m_max'] == null ? undefined : json['wind_speed_10m_max'],
        'windGusts10mMax': json['wind_gusts_10m_max'] == null ? undefined : json['wind_gusts_10m_max'],
        'windDirection10mDominant': json['wind_direction_10m_dominant'] == null ? undefined : json['wind_direction_10m_dominant'],
        'shortwaveRadiationSum': json['shortwave_radiation_sum'] == null ? undefined : json['shortwave_radiation_sum'],
        'uvIndexMax': json['uv_index_max'] == null ? undefined : json['uv_index_max'],
        'uvIndexClearSkyMax': json['uv_index_clear_sky_max'] == null ? undefined : json['uv_index_clear_sky_max'],
        'et0FaoEvapotranspiration': json['et0_fao_evapotranspiration'] == null ? undefined : json['et0_fao_evapotranspiration'],
    };
}

export function DailyResponseToJSON(value?: DailyResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'time': value['time'],
        'temperature_2m_max': value['temperature2mMax'],
        'temperature_2m_min': value['temperature2mMin'],
        'apparent_temperature_max': value['apparentTemperatureMax'],
        'apparent_temperature_min': value['apparentTemperatureMin'],
        'precipitation_sum': value['precipitationSum'],
        'precipitation_hours': value['precipitationHours'],
        'weather_code': value['weatherCode'],
        'sunrise': value['sunrise'],
        'sunset': value['sunset'],
        'wind_speed_10m_max': value['windSpeed10mMax'],
        'wind_gusts_10m_max': value['windGusts10mMax'],
        'wind_direction_10m_dominant': value['windDirection10mDominant'],
        'shortwave_radiation_sum': value['shortwaveRadiationSum'],
        'uv_index_max': value['uvIndexMax'],
        'uv_index_clear_sky_max': value['uvIndexClearSkyMax'],
        'et0_fao_evapotranspiration': value['et0FaoEvapotranspiration'],
    };
}

