import { FC } from "react";
import { Box, Icon, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

interface WindDirectionProps {
  windDirection: number;
  routeDirection: number;
}

export const WindDirection: FC<WindDirectionProps> = ({
  windDirection,
  routeDirection,
}) => {
  const isFavorable = Math.abs(windDirection - routeDirection) < 90;
  return (
    <Box
      sx={{
        padding: 1,
      }}
    >
      <img
        src={`wind_direction_${isFavorable ? "green" : "red"}.png`}
        alt="wind icon"
        height={16}
        width={25}
        style={{
          transform: `rotate(${(windDirection + 90) % 360}deg)`,
        }}
      />
    </Box>
  );
};
