import {
  MapContainer,
  Polyline,
  TileLayer,
  Marker,
  Popup,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect, useState } from "react";
import L, { LatLngTuple } from "leaflet";
import { Skeleton, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useError } from "../wrappers/GlobalMessageProvider";
import { FullscreenControl } from "react-leaflet-fullscreen";
import { TripStepTrackPoint } from "../../api/backend/models/TripStepTrackPoint";
import { TrackPointsApi } from "../../api/backend/apis/TrackPointsApi";

interface StepTrackProps {
  stepId: number;
  style?: React.CSSProperties;
  departureCity: string;
  arrivalCity: string;
}

export const StepTrack = ({
  stepId,
  style,
  departureCity,
  arrivalCity,
}: StepTrackProps) => {
  const [track, setTrack] = useState<TripStepTrackPoint[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchTrack = async (stepId: number) => {
    const api = new TrackPointsApi();
    return api.tripstepTrackPointsList({ tripStepId: stepId });
  };

  const error = useError();

  useEffect(() => {
    setIsLoading(true);
    fetchTrack(stepId)
      .then((track) => {
        setTrack(track);
        setIsLoading(false);
      })
      .catch((err) => error("APIErrorTrack"));
  }, [stepId]);

  const getCenter = (track: TripStepTrackPoint[]): LatLngTuple => {
    const latitudes = track.map((point) => point.trackPointLatitude);
    const longitudes = track.map((point) => point.trackPointLongitude);
    const minLatitude = Math.min(...latitudes);
    const maxLatitude = Math.max(...latitudes);
    const minLongitude = Math.min(...longitudes);
    const maxLongitude = Math.max(...longitudes);
    return [(minLatitude + maxLatitude) / 2, (minLongitude + maxLongitude) / 2];
  };

  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        width={style?.width || 400}
        height={style?.height || 200}
      />
    );
  }
  if (!track || track.length === 0) {
    return (
      <Typography variant="h6">
        <FormattedMessage id="noTrackData" />
      </Typography>
    );
  }
  return (
    <Stack>
      <MapContainer
        center={getCenter(track)}
        zoom={8}
        scrollWheelZoom={false}
        style={style}
      >
        <FullscreenControl position="topright" />
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker
          position={[track[0].trackPointLatitude, track[0].trackPointLongitude]}
          icon={L.icon({
            iconUrl: "bike-pin-start.svg",
            iconSize: [32, 32],
            iconAnchor: [16, 32],
          })}
        >
          <Popup>
            <Typography variant="h6">
              <FormattedMessage
                id="stepStart"
                values={{ city: departureCity }}
              />
            </Typography>
          </Popup>
        </Marker>
        <Marker
          position={[
            track[track.length - 1].trackPointLatitude,
            track[track.length - 1].trackPointLongitude,
          ]}
          icon={L.icon({
            iconUrl: "bike-pin-finish.svg",
            iconSize: [32, 32],
            iconAnchor: [16, 32],
          })}
        >
          <Popup>
            <Typography variant="h6">
              <FormattedMessage
                id="stepFinish"
                values={{ city: arrivalCity }}
              />
            </Typography>
          </Popup>
        </Marker>
        <Polyline
          positions={track.map((point) => [
            point.trackPointLatitude,
            point.trackPointLongitude,
          ])}
        />
      </MapContainer>
    </Stack>
  );
};
