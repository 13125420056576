/* tslint:disable */
/* eslint-disable */
/**
 * BikeTrip API
 * API for BikeTrip
 *
 * The version of the OpenAPI document: v1
 * Contact: lalou_68@hotmail.fr
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TripStepElevationProfile
 */
export interface TripStepElevationProfile {
    /**
     * 
     * @type {number}
     * @memberof TripStepElevationProfile
     */
    trackPointMeter: number;
    /**
     * 
     * @type {number}
     * @memberof TripStepElevationProfile
     */
    trackPointElevation?: number;
}

/**
 * Check if a given object implements the TripStepElevationProfile interface.
 */
export function instanceOfTripStepElevationProfile(value: object): boolean {
    if (!('trackPointMeter' in value)) return false;
    return true;
}

export function TripStepElevationProfileFromJSON(json: any): TripStepElevationProfile {
    return TripStepElevationProfileFromJSONTyped(json, false);
}

export function TripStepElevationProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): TripStepElevationProfile {
    if (json == null) {
        return json;
    }
    return {
        
        'trackPointMeter': json['track_point_meter'],
        'trackPointElevation': json['track_point_elevation'] == null ? undefined : json['track_point_elevation'],
    };
}

export function TripStepElevationProfileToJSON(value?: TripStepElevationProfile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'track_point_meter': value['trackPointMeter'],
        'track_point_elevation': value['trackPointElevation'],
    };
}

