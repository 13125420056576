import { Done, Info, PlayArrow, Schedule } from "@mui/icons-material";
import { Badge, Chip, Icon, Tooltip } from "@mui/material";
import { FC } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

export type StatusSlug = "planned" | "in_progress" | "completed";

interface StepStatusIndicatorProps {
  status: StatusSlug;
}

export const StepStatusIndicator: FC<StepStatusIndicatorProps> = ({
  status,
}) => {
  const intl = useIntl();

  const statusIconsAndColors: {
    [key in StatusSlug]: {
      icon: JSX.Element;
      color: "info" | "warning" | "success";
    };
  } = {
    planned: {
      icon: <Schedule />,
      color: "info",
    },
    in_progress: {
      icon: <PlayArrow />,
      color: "warning",
    },
    completed: {
      icon: <Done />,
      color: "success",
    },
  };

  const navigate = useNavigate();

  return (
    <Tooltip
      onClick={() => {
        if (status === "in_progress") {
          navigate("/current-step");
        }
      }}
      title={
        status === "planned"
          ? intl.formatMessage({ id: "plannedExplanation" })
          : status === "in_progress"
          ? intl.formatMessage({ id: "inProgressExplanation" })
          : ""
      }
    >
      <Badge badgeContent={<Info />} sx={{ color: "white" }}>
        <Chip
          label={intl.formatMessage({ id: status })}
          icon={statusIconsAndColors[status].icon}
          color={statusIconsAndColors[status].color}
          variant="filled"
          sx={{ cursor: status === "in_progress" ? "pointer" : "default" }}
        />
      </Badge>
    </Tooltip>
  );
};
