import { Box, Typography } from "@mui/material";
import { FC } from "react";
import { Styles } from "../../types/Styles";

export interface FrenchTownSignProps {
  city: string;
}

export const FrenchTownSign: FC<FrenchTownSignProps> = ({ city }) => {
  return (
    <Box sx={FrenchTownSignStyles.container}>
        <Typography variant="h5" style={FrenchTownSignStyles.cityLabel}>{city}</Typography>
    </Box>
  );
}

const FrenchTownSignStyles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px',
    paddingLeft: '20px',
    paddingRight: '20px',
    border: '3px solid red',
    borderRadius: '5px',
    textTransform: 'uppercase',
    margin: '1px',
    backgroundColor: 'white',
  },
  cityLabel: {
    color: 'black',
    fontWeight: 'bold',
    fontSize: '2em',
    fontFamily: 'Arial',
  },
};